import {
  ColorCriteria,
  DataPeriod,
  EvidencePeriodData,
  KnownAssessment,
  Measurement,
  MeasurementType,
} from "../../store/onboarding/types";
import { OnboardingStatesValues, UserInfo } from "../../store/auth/types";
import { Grade, TimePeriodOptions } from "../../store/groups/types";
import {
  Intervention,
  InterventionGroup,
  InterventionPlan,
  InterventionPlanStates,
  StudentGoal,
} from "../../store/onboarding/cases/types";
import { formatDate, formatDateWithoutLocal } from "./DateTimeUtils";
import _ from "lodash";

export const pickColor = (
  value: string | number | undefined,
  measure: Measurement,
  grade?: Grade,
  dataPeriod?: DataPeriod,
  evidencePeriodData?: EvidencePeriodData | undefined
) => {
  if (!value || !measure) {
    return undefined;
  }
  const color = undefined;
  let {
    type,
    color_criteria,
    custom_color_criteria,
    grade_specific_color_criteria,
    id
  } = measure;
  
   
  let isObject = function(a:any) {
    return (!!a) && (a.constructor === Object);
  };
  

  let foundCriteria: ColorCriteria | undefined;
  let colorCriteriaNew = [];
  if (
    type !== undefined &&
    (color_criteria || custom_color_criteria || grade_specific_color_criteria)
  ) {
    let allColorCriteria: Array<ColorCriteria> = [];


    let colorCriteria: any = custom_color_criteria;
    if((colorCriteria !== null) && (isObject(colorCriteria))) {
      for (const gradeKey in colorCriteria) {
        for (const timePeriodKey in colorCriteria[gradeKey]) {
          for (let index = 0; index < colorCriteria[gradeKey][timePeriodKey].length; index++) {
            if(colorCriteria[gradeKey][timePeriodKey][index] !== null) {
              let element = colorCriteria[gradeKey][timePeriodKey][index];
              element['grade'] = gradeKey;
              element['time_period'] = timePeriodKey;
              colorCriteriaNew.push(element);
            }
          }
        }
      }
      custom_color_criteria = colorCriteriaNew;
    }

    if ( 
      (color_criteria != null && Array.isArray(color_criteria)) ||
      (Array.isArray(custom_color_criteria) && custom_color_criteria.length)
      && grade && dataPeriod
    ) {
      
      let new_custom_color_criteria: Array<ColorCriteria> = [];

      if (
        Array.isArray(custom_color_criteria) &&
        custom_color_criteria.length
      ) {
        for (let index = 0; index < custom_color_criteria!.length; index++) {
          const element = custom_color_criteria![index];
          if (
            element.grade == grade &&
            element.time_period == dataPeriod?.time_period
          ) {
            new_custom_color_criteria.push(element);
          }
        }

        for (let i = 0; i < evidencePeriodData!.evidence_column_groups.length; i++) {
          const element = evidencePeriodData?.evidence_column_groups[i];
          for (let j = 0; j < element!.evidence_columns.length; j++) {
            const ele = element!.evidence_columns[j];

            if((ele.measurement !== null) && (ele.measurement.id == id)) {
              //console.log(evidenceColumnGroup.assessment.name, evidenceColumnGroup.assessment.with_reading_algorithm);
              if ((element!==undefined) && (element.assessment.use_grade_specific_color_criteria == false)){
                new_custom_color_criteria = [...custom_color_criteria]
              }
            }
            
          }
        }
      }
      //console.log('new_custom_color_criteria', new_custom_color_criteria)
      allColorCriteria = [
        ...(color_criteria != null ? color_criteria : []),
        ...(new_custom_color_criteria ? new_custom_color_criteria : []),
        //...(custom_color_criteria ? custom_color_criteria : []),
      ];
    } else if (grade_specific_color_criteria && grade && dataPeriod) {
      const forGrade = grade_specific_color_criteria[grade];
      if (forGrade) {
        allColorCriteria = forGrade[dataPeriod.time_period] || [];
      }
    }
   switch (type) {
      //@ts-ignore
      case MeasurementType.NUMBER:
      case MeasurementType.PERCENTAGE:
        if (+value > measure.range_max!) {
          foundCriteria = allColorCriteria.find((colorCriteria) => {
            if (measure) return measure.range_max! === colorCriteria?.range_max!;
          });
        } else {
          foundCriteria = allColorCriteria.find((colorCriteria) => {
            if (colorCriteria) {
              return (
                +value >= +colorCriteria.range_min! &&
                +value <= +colorCriteria?.range_max!
              );
            }
          });
        }
        if (foundCriteria) return foundCriteria.color;
        break;
      case MeasurementType.CATEGORY:
        foundCriteria = allColorCriteria.find(
          (colorCriteria) => +value === colorCriteria.order
        );
        if (foundCriteria) return foundCriteria.color;
        break;
      default:
        break;
    }
  }
  return color;
};

export const pickTextColorBasedOnBgColor = (
  bgColor: string | undefined,
  lightColor: string = "#FFFFFF",
  darkColor: string = "#455a64"
) => {
  if (!bgColor) return darkColor;
  const color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16); // hexToR
  const g = parseInt(color.substring(2, 4), 16); // hexToG
  const b = parseInt(color.substring(4, 6), 16); // hexToB
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor;
};

export const drawCell = (value: string, measure: Measurement) => {
  if (!measure) {
    return value;
  }

  const measureType = measure.type!;

  switch (measureType) {
    case MeasurementType.PERCENTAGE:
      return `${value}%`;
    case MeasurementType.NUMBER:
    case MeasurementType.CATEGORY:
    default:
      return value;
  }
};

export const getCurrentStep = (userInfo?: UserInfo) => {
  if (!userInfo) return 0;
  return OnboardingStatesValues[userInfo.profile.onboarding_state];
};

export const getGoalTitle = (studentGoal: StudentGoal) => {
  return studentGoal?.measurement.is_gas
    ? `Adjust behavior using Goal Attainment Scale by ${formatDateWithoutLocal(
        studentGoal?.target_date,
        "MMMM D, YYYY"
      )}`
    : studentGoal?.target_value && studentGoal?.target_date
    ? `Will ${
        studentGoal?.goal_statement
          ? `${studentGoal?.goal_statement} by achieving`
          : "achieve"
      } ${
        studentGoal?.measurement.type === MeasurementType.CATEGORY
          ? studentGoal?.measurement.assessment?.name ?? studentGoal?.target_value
          : // ? studentGoal?.measurement.possible_values?.find(
            //     pv => pv.order === +studentGoal?.target_value
            //   )?.display_name ?? studentGoal?.target_value
            studentGoal?.target_value
      } on ${studentGoal?.measurement.display_name}${
        studentGoal?.observation_unit_description
          ? ` of ${studentGoal?.observation_unit_description}`
          : ""
      } by ${formatDateWithoutLocal(studentGoal?.target_date, "MMMM D, YYYY")}`
    : studentGoal?.measurement.known_assessment ===
      KnownAssessment.ReadyCoach_Indicator
    ? `Use ReadyCoach Indicators to meet goal by ${formatDateWithoutLocal(
        studentGoal?.target_date,
        "MMMM D, YYYY"
      )}`
    : `Goal pending Observation with ${studentGoal?.measurement.display_name}`;
};

export const interventionGroupHasEnoughData = (
  interventionGroup: InterventionGroup
) => {
  if (interventionGroup.progress_overview) {
    if (interventionGroup.progress_overview.implementation_checks.length) { //RC-1422
      return true;
    }

    let totalProgresses = 0;
    interventionGroup.progress_overview.student_goal_progress.forEach((sgp) => {
      totalProgresses += sgp.logged_progress.length;
    });

    if (totalProgresses) { //RC-1422
      return true;
    }
  }
  return false;
};

export const interventionGroupDataCount = (
  interventionGroup: InterventionGroup
): number => {
  if (interventionGroup.progress_overview) {
    if (interventionGroup.progress_overview.implementation_checks.length) {
      return interventionGroup.progress_overview.implementation_checks.length;
    }

    let totalProgresses = 0;
    interventionGroup.progress_overview.student_goal_progress.forEach((sgp) => {
      totalProgresses += sgp.logged_progress.length;
    });

    return totalProgresses;
  }
  return 0;
};

export const changeInterventionPlanOrder = (
  intervention_plan: InterventionPlan | null,
  interventions: Intervention[],
  sourceIndex: number,
  destinationIndex: number,
  draggableId: string
): InterventionPlan => {
  if (
    intervention_plan &&
    intervention_plan.plan_items &&
    !interventions.some(
      (i) =>
        !intervention_plan.plan_items?.some((pi) => i.id === pi.intervention_id)
    )
  ) {
    return {
      ...intervention_plan,
      plan_items: intervention_plan.plan_items.map((item) => {
        if (item.order === sourceIndex) {
          return { ...item, order: destinationIndex };
        }
        if (item.order > sourceIndex) {
          if (item.order > destinationIndex) {
            return item;
          } else {
            return { ...item, order: item.order - 1 };
          }
        } else {
          if (item.order < destinationIndex) {
            return item;
          } else {
            return { ...item, order: item.order + 1 };
          }
        }
      }),
    };
  } else {
    const interventionIds = interventions.map((i) => i.id);
    interventionIds.splice(sourceIndex, 1);
    interventionIds.splice(destinationIndex, 0, Number(draggableId));

    return {
      plan_items: interventionIds.map((id, index) => ({
        intervention_id: id,
        order: index,
        state: InterventionPlanStates.ACTIVE,
      })),
    };
  }
};

export const removeFromInterventionPlan = (
  removable: Intervention,
  interventions: Intervention[],
  intervention_plan: InterventionPlan | null
): InterventionPlan => {
  if (
    intervention_plan &&
    intervention_plan.plan_items &&
    !interventions.some(
      (i) =>
        !intervention_plan.plan_items?.some((pi) => i.id === pi.intervention_id)
    )
  ) {
    const removedIndex = intervention_plan.plan_items.find(
      (ac) => ac.intervention_id === removable.id
    )?.order;
    if (removedIndex != null) {
      const changedPlan = { ...intervention_plan };

      changedPlan.plan_items = changedPlan.plan_items
        ?.filter((ac) => ac.intervention_id !== removable.id)
        .map((ac) =>
          ac.order > removedIndex ? { ...ac, order: ac.order - 1 } : ac
        );
      return changedPlan;
    } else {
      return intervention_plan;
    }
  } else {
    const interventionIds = interventions
      .filter((int) => int.id !== removable.id)
      .map((i) => i.id);
    return {
      plan_items: interventionIds.map((id, index) => ({
        intervention_id: id,
        order: index,
        state: InterventionPlanStates.ACTIVE,
      })),
    };
  }
};

export const sortInterventionsByInterventionPlan = (
  interventions: Intervention[],
  intPlan: InterventionPlan | null
): Intervention[] => {
  const interventionPlan = checkedInterventionPlan(interventions, intPlan);

  if (interventionPlan) {
    return interventions.sort((a, b) => {
      const aPlan = interventionPlan.plan_items?.find(
        (item) => item.intervention_id === a.id
      );
      const bPlan = interventionPlan.plan_items?.find(
        (item) => item.intervention_id === b.id
      );

      if (!aPlan && !bPlan) {
        return 0;
      }
      if (!aPlan) {
        return 1;
      }
      if (!bPlan) {
        return -1;
      }

      return aPlan.order - bPlan.order;
    });
  }
  return interventions;
};

export const addInterventionToPlan = (
  intervention_plan: InterventionPlan | null,
  createdIntervention: Intervention,
  interventions: Intervention[]
): InterventionPlan => {
  if (
    intervention_plan &&
    intervention_plan.plan_items &&
    !interventions.some(
      (i) =>
        !intervention_plan.plan_items?.some((pi) => i.id === pi.intervention_id)
    )
  ) {
    const changedPlan = { ...intervention_plan };

    changedPlan.plan_items?.push({
      intervention_id: createdIntervention.id,
      order: intervention_plan.plan_items.length,
      state: InterventionPlanStates.ACTIVE,
    });

    return changedPlan;
  } else {
    const interventionIds = interventions.map((i) => i.id);
    return {
      plan_items: [
        ...interventionIds.map((id, index) => ({
          intervention_id: id,
          order: index,
          state: InterventionPlanStates.ACTIVE,
        })),
        {
          intervention_id: createdIntervention.id,
          order: interventionIds.length,
          state: InterventionPlanStates.ACTIVE,
        },
      ],
    };
  }
};

export const generateDefaultInterventionPlan = (
  interventions: Intervention[]
): InterventionPlan => ({
  plan_items: interventions.map((intervention, index) => ({
    intervention_id: intervention.id,
    order: index,
    state: InterventionPlanStates.ACTIVE,
  })),
});

export const checkedInterventionPlan = (
  interventions: Intervention[],
  intervention_plan: InterventionPlan | null
): InterventionPlan => {
  if (intervention_plan?.plan_items) {
    const interventionIds = _.chain(interventions)
      .map("id")
      .sortBy()
      .value();
    const planInterventionIds = _.chain(intervention_plan.plan_items)
      .map("intervention_id")
      .filter((v) => typeof v === "number")
      .sortBy()
      .value();

    const sameInterventions = _.isEqual(interventionIds, planInterventionIds);

    if (sameInterventions) {
      return intervention_plan;
    } else {
      const missingIds = _.difference(interventionIds, planInterventionIds);
      const maxOrderValue =
        _.maxBy(intervention_plan.plan_items, "order")?.order ?? 0;

      return {
        plan_items: [
          ...intervention_plan.plan_items.filter((pi) => pi.intervention_id),
          ...missingIds.map((id, index) => ({
            intervention_id: id,
            order: maxOrderValue + (index + 1), //todo
            state: !interventions.find((int) => int.id === id)?.is_active
              ? InterventionPlanStates.COMPLETED
              : InterventionPlanStates.ACTIVE,
          })),
        ],
      };
    }
  } else {
    return generateDefaultInterventionPlan(interventions);
  }
};

export const htmlEncoding = (str:string) => {
  return str.replace(/&/g, "&amp;")
  .replace(/</g, "&lt;")
  .replace(/>/g, "&gt;")
  .replace(/"/g, "&quot;")
  .replace(/'/g, "&#039;")
}

export const htmlDecoding = (str:string) => {
  let doc = new DOMParser().parseFromString(str, "text/html");
  return doc.documentElement.textContent || '';
}
