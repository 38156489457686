import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CustomDateInput from "../../../../../../../../common/onboarding/third-step/set-goal-modal/common/CustomDateInput";
import DatePicker from "react-datepicker";
import { Form, Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "../../../../../../../../../store";
import BaseSelect from "react-select";
import CreatableSelect from "react-select/creatable";
import { CoachInfo, ReactSelectOption, TeacherInfo } from "../../../../../../../../../store/onboarding/types";
import { ValueType } from "react-select/src/types";
import {
  createAttendance,
  createLessonNotes,
  updateLessonNotes,
} from "../../../../../../../../../store/onboarding/cases/actions";
import {
  AttachedResource,
  ChecklistItem,
  Intervention,
  InterventionGroup,
  InterventionPlanStates,
  LessonNoteRecordTag,
  LessonNoteRecordTagType,
  LessonNoteRecordTagTypeDisplayedValues,
  LessonNotes,
  StudentGoal,
} from "../../../../../../../../../store/onboarding/cases/types";
import { useLoading } from "../../../../../../../../../utils/hooks/useLoading";
import { toastr } from "react-redux-toastr";
import moment from "moment";
import { getFullName } from "../../../../../../../../../utils/NamesUtils";
import _ from "lodash";
import AttendanceStudentsList from "../attendance-tab/AttendanceStudentsList";
import AddResourcesForm from "../../../../../explore-and-learn/intervention-library/new-intervention-modal/intervention-steps-form/AddResourcesForm";
import { UserAssignmentRole, UserInfo } from "../../../../../../../../../store/auth/types";
import FixRequiredSelect from "../../../../../../../../common/required-select/FixRequiredSelect";

type Props = {
  show?: boolean;
  onHideForm: () => void;
  selectedLessonNotes: LessonNotes | undefined;
  selectedGroup: InterventionGroup;
};

const ID_GENERAL_NOTES = -1;

const LessonNotesUpsertForm: FunctionComponent<Props> = (props) => {
  const { show, onHideForm, selectedLessonNotes, selectedGroup } = props;
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [notes, setNotes] = useState<string>("");

  const selectedInterventionGroup = useSelector(
    (s: ApplicationState) => s.cases.selectedInterventionGroup
  );

  let mergedInterventions = useMemo(() => {
    let mergedInterventions: Intervention[] = [];
    if(selectedLessonNotes) {
      mergedInterventions = [...selectedInterventionGroup!.interventions, ...selectedInterventionGroup!.archived_interventions]
    }
    return Object.values(
      mergedInterventions.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
    );
  },[selectedLessonNotes])

  const interventions = useMemo(() => {
    if(selectedLessonNotes) {
      
      let activeIntervention = 
        selectedInterventionGroup?.interventions
        .filter((intervention) => selectedInterventionGroup.intervention_plan?.plan_items?.some((planItem) => planItem.intervention_id == intervention.id && planItem.state == InterventionPlanStates.ACTIVE));
      
      let mergeWithActiveIntervention:any[] = 
            mergedInterventions.filter((inter:any) => selectedLessonNotes.interventions.includes(inter.id)).concat(activeIntervention)
      
      return Object.values(
        mergeWithActiveIntervention.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
      );
    } else {
      return selectedInterventionGroup?.interventions.filter((intervention) => {
        if(selectedInterventionGroup.intervention_plan?.plan_items?.length) {
          return selectedInterventionGroup.intervention_plan?.plan_items.some((interventionPlan) => (interventionPlan.intervention_id == intervention.id) && (interventionPlan.state == InterventionPlanStates.ACTIVE))
        } else {
          return intervention;
        }
      }) ?? [];
    }
  }, [selectedInterventionGroup, selectedLessonNotes]);

  const [selectedNotesFor, setSelectedNotesFor] = useState<
    ReactSelectOption<number>[]
  >([]);

  const generalOption = useMemo(
    () => ({ value: ID_GENERAL_NOTES, label: "General Notes" }),
    []
  );

  let allStudent: number[] = [];
  selectedGroup.student_goals.map((goal) => {
    if (!goal.archived) {
      allStudent.push(goal.student.id!);
    }
  });

  const options: ReactSelectOption<number>[] = useMemo(() => {
    return [
      generalOption,
      ...(interventions?.map((int:any) => ({
        value: int.id,
        label: int.name,
      })) || []),
    ];
  }, [interventions]);

  const handleSelectedNotesForChange = (
    values: ValueType<ReactSelectOption<number>, true>
  ) => {
    setSelectedNotesFor((values as any) ?? []);
  };

  const [tags, setTags] = useState<Omit<LessonNoteRecordTag, "order">[]>([]);
  const [selectedTags, setSelectedTags] = useState<
    Omit<LessonNoteRecordTag, "order">[]
  >([]);

  const handleCreateNextStep = (tag: string) => {
    const newTag = { type: LessonNoteRecordTagType.Custom, text: tag };
    setTags((tags) => [...tags, newTag]);
    setSelectedTags((tags) => [...tags, newTag]);
  };
  const handleChangeNextSteps = (
    options: ValueType<Omit<LessonNoteRecordTag, "order">, true>
  ) => {
    setSelectedTags((options as Omit<LessonNoteRecordTag, "order">[]) ?? []);
  };

  const [viewableBy, setViewableBy] = useState<number[]>([]);

  const myId = useSelector((s: ApplicationState) => s.auth.userInfo?.id);
  const loggedInUserInfo = useSelector((s: ApplicationState) => s.auth.userInfo);

  useEffect(() => {
    if (show) {
      if (selectedLessonNotes) {
        const notesForInterventions = selectedLessonNotes.interventions.map(
          (intId) => options.find((o) => o.value === intId)!
        );
        console.log('notesForInterventions', selectedLessonNotes.interventions)
        const notesFor = selectedLessonNotes.is_general
          ? [generalOption, ...notesForInterventions]
          : notesForInterventions;
        setNotes(selectedLessonNotes.notes);
        setSelectedNotesFor(notesFor);
        setSelectedDate(moment(selectedLessonNotes.date).toDate());
        setSelectedTags(selectedLessonNotes.record_tags);
        setViewableBy(selectedLessonNotes.viewable_by || myId);
        setAttachments(selectedLessonNotes.attachments ?? []);
      } else {
        setNotes("");
        setSelectedNotesFor([]);
        setSelectedDate(new Date());
        setSelectedTags([]);
        setViewableBy([myId!]);
        setAttachments([]);
      }
    }
  }, [show]);

  const checkAttendance = () => {
    if (showAntecedentForm) {
      //RC-832 stop the attandance api call
      //handleAttendanceAdd(); 
    }
      onHideForm(); 
  };

  const creationLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.createLessonNotes
  );
  const creationError = useSelector(
    (s: ApplicationState) => s.cases.errors.createLessonNotes
  );
  const onCreationSuccess = useCallback(() => {
    toastr.success("Success", "Lesson notes is added successfully!");
    checkAttendance();
  }, [checkAttendance]);
  useLoading(creationLoading, creationError, onCreationSuccess);

  const updateLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.updateLessonNotes
  );
  const updateError = useSelector(
    (s: ApplicationState) => s.cases.errors.updateLessonNotes
  );
  const onUpdateSuccess = useCallback(() => {
    toastr.success("Success", "Lesson notes is updated successfully!");
    checkAttendance();
  }, [checkAttendance]);
  useLoading(updateLoading, updateError, onUpdateSuccess);

  const createAttendanceLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.createAttendance
  );
  const createAttendanceError = useSelector(
    (s: ApplicationState) => s.cases.errors.createAttendance
  );
  const onCreateAttendanceSuccess = useCallback(() => {
    toastr.success("Success", "Attendance is added successfully!");
    onHideForm();
  }, []);
  useLoading(
    createAttendanceLoading,
    createAttendanceError,
    onCreateAttendanceSuccess
  );

  const handleAttendanceAdd = () => {
    dispatch(
      createAttendance(selectedInterventionGroup?.id!, {
        date: selectedDate!.toISOString(),
        interventions: selectedNotesFor
          .filter((o) => o.value !== ID_GENERAL_NOTES)
          .map((o) => o.value),
        absent_students:
          allStudent
            .filter((st) => !selectedStudents.includes(st))
            .map((st) => st) ?? [],
        present_students: selectedStudents,
      })
    );
  };

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
     if (selectedInterventionGroup?.id && selectedDate) {
      // if(!justMeChecked && !viewableBy.length) {
      //   toastr.error("This notes for:", "Please select for whom this note is intended.");
      //   return;
      // }
      // if(showAntecedentForm && !selectedStudents.length) {
      //   toastr.error("Alert!!", "Please select the student or switch off TAG STUDENT TO NOTES.");
      //   return;
      // }
      const body: Omit<
        LessonNotes,
        "id" | "intervention_group_id" | "user" | "attachments" | "group"
      > & {
        attachments: number[];
      } = {
        date: selectedDate.toISOString(),
        notes: notes,
        interventions: selectedNotesFor
          .filter((o) => o.value !== ID_GENERAL_NOTES)
          .map((o) => o.value),
        is_general: selectedNotesFor.some((o) => o.value === ID_GENERAL_NOTES),
        record_tags: selectedTags.map((tag, index) => ({
          ...tag,
          order: index,
        })),
        viewable_by: _.union([...viewableBy, myId!]),
        attachments: attachments.map((a) => a.id!),
        pertaining_students: selectedStudents
      };
      

      if (selectedLessonNotes) {
        dispatch(
          updateLessonNotes(selectedInterventionGroup.id, {
            id: selectedLessonNotes.id,
            ...body,
          })
        );
      } else {
        dispatch(createLessonNotes(selectedInterventionGroup.id, body));
      }
    }
  };

  const defaultTags: Omit<LessonNoteRecordTag, "order">[] = useMemo(() => {
    return Object.values(LessonNoteRecordTagType)
      .filter((value) => value !== LessonNoteRecordTagType.Custom)
      .map((value) => ({
        type: value,
        text: LessonNoteRecordTagTypeDisplayedValues[value],
      }));
  }, []);

  

  const allEducators = useMemo(() => {
    let allUsers: any[] = [];
    if(Object.keys(selectedGroup.teacher_assignment!).length) {
      allUsers.push({
        id: selectedGroup.teacher_assignment?.user.id,
        first_name: selectedGroup.teacher_assignment?.user.first_name,
        last_name: selectedGroup.teacher_assignment?.user.last_name,
      })
    }
    if(Object.keys(selectedGroup.coach_assignment!).length) {
      allUsers.push({
        id: selectedGroup.coach_assignment?.user.id,
        first_name: selectedGroup.coach_assignment?.user.first_name,
        last_name: selectedGroup.coach_assignment?.user.last_name,
      })
    }
    if(selectedGroup.associated_assignments.length) {
      selectedGroup.associated_assignments.forEach((associate) => {
        return allUsers.push({
          id: associate.user.id,
          first_name: associate.user.first_name ,
          last_name: associate.user.last_name,
        })
      }
      )
    }
    return Object.values(
      allUsers.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
    )
  },[selectedGroup.teacher_assignment, selectedGroup.coach_assignment, selectedGroup.additional_educator_plan])

  
  const justMeChecked = useMemo(
    () =>  (viewableBy.length === 1 && viewableBy.includes(myId!)),
    [viewableBy, myId]
  );

  const allGroupChecked = useMemo(() => {
    return (
      allEducators
        .filter((edu:any) => edu.id !== myId)
        .every((educator:any) => viewableBy.includes(educator.id))
    )
  }, [viewableBy, allEducators]);

  const groupCheckboxRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setTags(defaultTags);
  }, [defaultTags]);

  const handleViewableByChange = (userId: number) => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checked = e.target.checked;
    const newViewableBy = checked
      ? [...viewableBy, userId]
      : viewableBy.filter((id) => id !== userId);

    if (groupCheckboxRef.current) {
      if (newViewableBy.length) {
        const forAllGroupSelected = 
                  allEducators
                    .filter((edu:any) => edu.id !== myId)
                    .every((educator:any) => viewableBy.includes(educator.id));
        groupCheckboxRef.current.indeterminate = !forAllGroupSelected;
      } else {
        groupCheckboxRef.current.indeterminate = false;
      }
    }
    setViewableBy(newViewableBy);
  };


  const [showAntecedentForm, setShowAntecedentForm] = useState(true);

  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);

  const [attachments, setAttachments] = useState<AttachedResource[]>([]);

  if (!show) {
    return null;
  }

  const Select = (
    props: JSX.IntrinsicAttributes &
      JSX.IntrinsicClassAttributes<FixRequiredSelect> &
      Readonly<any> &
      Readonly<{ children?: React.ReactNode }>
  ) => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={options}
      menuShouldBlockScroll={true}
    />
  );

  return (
    <div className="lesson-notes-upsert-form workspace-content-container">
      <form id={"lesson-notes-upsert-form "} onSubmit={handleFormSubmit}>
        <DatePicker
          required
          customInput={<CustomDateInput />}
          selected={selectedDate}
          onChange={setSelectedDate}
        />
        <Form.Group className="my-2">
          <Form.Label>Intervention (Optional)</Form.Label>
          <Select
            isMulti
            className="mb-2"
            name="notesFor"
            value={selectedNotesFor}
            options={options as any}
            onChange={handleSelectedNotesForChange}
            placeholder="Select..."
            styles={{ menuPortal: (base: any) => ({ ...base, zIndex: 1070 }) }}
            menuPortalTarget={document.body}
            // required={showAntecedentForm ? true : false}
          />
        </Form.Group>
        <Form.Group className="my-2">
          <Form.Label>Records & Next Steps (Optional)</Form.Label>
          <CreatableSelect
            isMulti
            isClearable
            value={selectedTags}
            options={tags as any}
            createOptionPosition="first"
            placeholder="Select or type tags..."
            onCreateOption={handleCreateNextStep}
            onChange={handleChangeNextSteps}
            getOptionLabel={(tag: any) => tag.label ?? tag.text}
            getOptionValue={(tag: any) => tag.value ?? tag.text}
            getNewOptionData={(inputValue, optionLabel) =>
              ({
                label: optionLabel,
                value: inputValue,
              } as any)
            }
            styles={{ menuPortal: (base) => ({ ...base, zIndex: 1070 }) }}
            menuPortalTarget={document.body}
            menuPosition={"absolute"}
            menuPlacement={"auto"}
          />
        </Form.Group>
        <Form.Group className="my-2">
          <Form.Label>Notes (Optional)</Form.Label>
          <Form.Control
            as={"textarea"}
            // required={showAntecedentForm ? false : true}
            value={notes}
            onChange={(e) => setNotes((e.target as HTMLInputElement).value)}
            type="text"
            placeholder="Type here..."
          />
        </Form.Group>                                                       
        <div className="mt-3 row col-8">
          <Form.Check
            type="switch"
            id="tag_student_to_notes"
            label="TAG STUDENTS TO NOTES"
            checked={showAntecedentForm}
            onClick={(e) => {
              setShowAntecedentForm((s) => !s);
              showAntecedentForm ? setSelectedStudents(allStudent) : '';
            }}
          />
        </div>
        {showAntecedentForm ? (
          <AttendanceStudentsList
            students={selectedInterventionGroup?.students ?? []}
            selectedStudents={selectedStudents}
            setSelectedStudents={setSelectedStudents}
            intervention={selectedInterventionGroup}
            selectedLessonNotes= {selectedLessonNotes}
          />
        ) : ''}

        <Form.Group className="my-2">
          <Form.Label>NOTE IS FOR: (Optional)</Form.Label>
          <Form.Check
            id={"just-me-checkbox"}
            custom
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setViewableBy(e.target.checked ? [myId!] : []);
              if (groupCheckboxRef.current) {
                groupCheckboxRef.current.indeterminate = false;
              }
            }}
            className="customDefaultCheckbox"
            type="checkbox"
            label={`${getFullName(loggedInUserInfo)} (Just Me)`}
            checked={justMeChecked}
          />
          <Form.Check
            id={"group-checkbox"}
            ref={groupCheckboxRef as any}
            custom
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const checked = e.target.checked;
              setViewableBy(
                checked ? allEducators
                            .filter((edu:any) => edu.id !== myId)
                            .map((educator:any) => educator.id) 
                        : []
              );
            }}
            className="customDefaultCheckbox"
            type="checkbox"
            label="The Group"
            checked={allGroupChecked}
          />
          {allEducators
            .filter((associateAssignment:any) => associateAssignment.id != myId)
            .map((aa:any) => {
            return (
              <Form.Check
                id={`associated-user-${aa.id}-checkbox`}
                key={`associated-user-${aa.id}-checkbox`}
                custom
                onChange={handleViewableByChange(aa.id)}
                className="customDefaultCheckbox ml-4"
                type="checkbox"
                label={getFullName(aa)}
                checked={viewableBy.includes(aa.id)}
              />
            );
          })}
        </Form.Group>
      </form>

      <AddResourcesForm
        attachments={attachments}
        onAttachmentsAdd={(resources) =>
          setAttachments((attachments) => [...attachments, ...resources])
        }
        onAttachmentRemove={(resourceId) =>
          setAttachments((attachments) =>
            attachments.filter((a) => a.id !== resourceId)
          )
        }
      />

      <div className="d-flex justify-content-between">
        <button className="whiteBtnSm" onClick={onHideForm}>
          Cancel
        </button>
        <button
          className="blueBtnSm"
          type="submit"
          form="lesson-notes-upsert-form "
        >
          Save{" "}
          {(creationLoading || updateLoading || createAttendanceLoading) && (
            <Spinner animation="border" size="sm" className="ml-1" />
          )}
        </button>
      </div>
    </div>
  );
};

export default LessonNotesUpsertForm;
