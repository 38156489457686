import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  Attendance,
  Intervention,
  InterventionGroup,
  InterventionPlanStates,
} from "../../../../../../../../../store/onboarding/cases/types";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { ApplicationState } from "../../../../../../../../../store";
import { toastr } from "react-redux-toastr";
import { useLoading } from "../../../../../../../../../utils/hooks/useLoading";
import DatePicker from "react-datepicker";
import CustomDateInput from "../../../../../../../../common/onboarding/third-step/set-goal-modal/common/CustomDateInput";
import { Spinner } from "react-bootstrap";
import {
  createAttendance,
  updateAttendance,
} from "../../../../../../../../../store/onboarding/cases/actions";
import AttendanceStudentsList from "./AttendanceStudentsList";
import BaseSelect from "react-select";
import { ReactSelectOption } from "../../../../../../../../../store/onboarding/types";
import { ValueType } from "react-select/src/types";
import FixRequiredSelect from "../../../../../../../../common/required-select/FixRequiredSelect";

type OwnProps = {
  show?: boolean;
  onHideForm: () => void;
  selectedAttendance?: Attendance;
  selectedGroup: InterventionGroup;
};

type Props = OwnProps;

const AttendanceUpsertForm: FunctionComponent<Props> = (props) => {
  const { show, onHideForm, selectedAttendance, selectedGroup } = props;
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [selectedStudents, setSelectedStudents] = useState<number[]>([]);
  const [selectedInterventions, setSelectedInterventions] = useState<
    ReactSelectOption<number>[]
  >([]);

  let mergedInterventions = useMemo(() => {
    let mergedInterventions: Intervention[] = [];
    if(selectedAttendance) {
      mergedInterventions = [...selectedGroup!.interventions, ...selectedGroup!.archived_interventions]
    }
    return Object.values(
      mergedInterventions.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
    );
  },[selectedAttendance])

  const options: ReactSelectOption<number>[] = useMemo(() => {
    let interventionsArray = [];
      if(selectedAttendance) {
        let activeIntervention = 
          selectedGroup?.interventions
          .filter((intervention) => selectedGroup.intervention_plan?.plan_items?.some((planItem) => planItem.intervention_id == intervention.id && planItem.state == InterventionPlanStates.ACTIVE));
        
        let mergeWithActiveIntervention: any[] = 
              mergedInterventions.filter((inter:any) => selectedAttendance.interventions.includes(inter.id)).concat(activeIntervention)
        
        interventionsArray = Object.values(
          mergeWithActiveIntervention.reduce((acc, obj) => ({ ...acc, [obj.id]: obj }), {})
        );
      } else {
        interventionsArray = selectedGroup.interventions.filter((intervention) => {
          if(selectedGroup.intervention_plan?.plan_items?.length) {
            return selectedGroup.intervention_plan?.plan_items.some((interventionPlan) => (interventionPlan.intervention_id == intervention.id) && (interventionPlan.state == InterventionPlanStates.ACTIVE))
          } else {
            return intervention;
          }
        });
      }

    return (
      interventionsArray.map((int:any) => ({
        value: int.id,
        label: int.name,
      })) ?? []
    );
  }, [selectedGroup.interventions, selectedAttendance]);

  let allStudent: number[] = [];
  selectedGroup.student_goals.map((goal) => {
    if (!goal.archived) {
      allStudent.push(goal.student.id!);
    }
  });

  useEffect(() => {
    if (show) {
      if (selectedAttendance) {
        setSelectedDate(moment(selectedAttendance.date).toDate());
        setSelectedStudents(selectedAttendance.present_students);
        setSelectedInterventions(
          selectedAttendance.interventions.map(
            (intId) => options.find((o) => o.value === intId)!
          )
        );
      } else {
        setSelectedDate(new Date());
        setSelectedStudents(allStudent);
        setSelectedInterventions([]);
      }
    }
  }, [show]);

  const creationLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.createAttendance
  );
  const creationError = useSelector(
    (s: ApplicationState) => s.cases.errors.createAttendance
  );
  const onCreationSuccess = useCallback(() => {
    toastr.success("Success", "Attendance is added successfully!");
    onHideForm();
  }, []);
  useLoading(creationLoading, creationError, onCreationSuccess);

  const updateLoading = useSelector(
    (s: ApplicationState) => s.cases.isLoading.updateAttendance
  );
  const updateError = useSelector(
    (s: ApplicationState) => s.cases.errors.updateAttendance
  );
  const onUpdateSuccess = useCallback(() => {
    toastr.success("Success", "Attendance is updated successfully!");
    onHideForm();
  }, []);
  useLoading(updateLoading, updateError, onUpdateSuccess);

  const handleFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedDate) {
      const body: Pick<
        Attendance,
        "date" | "interventions" | "absent_students" | "present_students"
      > = {
        date: selectedDate.toISOString(),
        interventions: selectedInterventions.map((int) => int.value),
        absent_students: allStudent
          .filter((st) => !selectedStudents.includes(st!))
          .map((st) => st!),
        present_students: selectedStudents,
      };

      if (selectedAttendance) {
        dispatch(
          updateAttendance(selectedGroup.id, {
            id: selectedAttendance.id,
            ...body,
          })
        );
      } else {
        dispatch(createAttendance(selectedGroup.id, body));
      }
    }
  };

  const handleSelectedInterventionsIdsChange = (
    values: ValueType<ReactSelectOption<number>, true>
  ) => {
    setSelectedInterventions((values as any) ?? []);
  };

  if (!show) {
    return null;
  }

  const Select = (
    props: JSX.IntrinsicAttributes &
      JSX.IntrinsicClassAttributes<FixRequiredSelect> &
      Readonly<any> &
      Readonly<{ children?: React.ReactNode }>
  ) => (
    <FixRequiredSelect
      {...props}
      SelectComponent={BaseSelect}
      options={options}
      menuShouldBlockScroll={true}
    />
  );

  return (
    <form className="attendance-upsert-form" onSubmit={handleFormSubmit}>
      <DatePicker
        required
        customInput={<CustomDateInput />}
        selected={selectedDate}
        onChange={setSelectedDate}
        maxDate={new Date()}
      />
      <AttendanceStudentsList
        students={selectedGroup.students}
        selectedStudents={selectedStudents}
        setSelectedStudents={setSelectedStudents}
        intervention={selectedGroup}
      />
      <Select
        menuPlacement="top"
        menuPosition="fixed"
        isMulti
        className="mb-2"
        name="selected-interventions"
        value={selectedInterventions}
        options={options as any}
        onChange={handleSelectedInterventionsIdsChange}
        placeholder="Select..."
        required
      />

      <div className="d-flex justify-content-between">
        <button className="whiteBtnSm" onClick={onHideForm}>
          Cancel
        </button>
        <button className="blueBtnSm" type="submit">
          Save{" "}
          {(creationLoading || updateLoading) && (
            <Spinner animation="border" size="sm" className="ml-1" />
          )}
        </button>
      </div>
    </form>
  );
};

export default AttendanceUpsertForm;
